
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexY5Ny83IJl7Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/2fa/index.vue?macro=true";
import { default as indexEg47hzBIZEMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/advisors-metrics/index.vue?macro=true";
import { default as countersignxF468bk52nMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/countersign.vue?macro=true";
import { default as indexquLjNQi5bJMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/index.vue?macro=true";
import { default as indexvUiFYwtHE3Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/index.vue?macro=true";
import { default as _91id_93Yss1e8Yu4WMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/assistants/[id].vue?macro=true";
import { default as indexdNY8DGfBpwMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/assistants/index.vue?macro=true";
import { default as _91id_935kG9ieVG72Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/[id].vue?macro=true";
import { default as index2Cj19LEknmMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/index.vue?macro=true";
import { default as _91id_93ckIl4qqogYMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/[id].vue?macro=true";
import { default as indexOBwgeXeQWgMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/index.vue?macro=true";
import { default as indexKxo8gIqhSRMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/case-reporting/index.vue?macro=true";
import { default as _91id_93g28Z6RfmhSMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/[id].vue?macro=true";
import { default as index3hcyHNApYyMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/index.vue?macro=true";
import { default as _91id_931sy2GPaLRvMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/chats/[id].vue?macro=true";
import { default as index7X2qLIYWC2Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/chats/index.vue?macro=true";
import { default as _91id_9346pk727ws7Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/[id].vue?macro=true";
import { default as indexSS7bWQuPRGMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/index.vue?macro=true";
import { default as indexJBOxt0OoVdMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/endorsements-metrics/index.vue?macro=true";
import { default as indexq13h1QKYueMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/forecasted-impact-page-metrics/index.vue?macro=true";
import { default as indexbA3doqd1UfMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations-endorsements/index.vue?macro=true";
import { default as _91id_93tog9sTshJQMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/[id].vue?macro=true";
import { default as indexezfcBaujDcMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/index.vue?macro=true";
import { default as index5OIQwW7y2HMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/grants/index.vue?macro=true";
import { default as indextuJcs3h1A5Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/home-metrics/index.vue?macro=true";
import { default as _91id_93j3dpEgnvs7Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/[id].vue?macro=true";
import { default as indexLyCIsAL10JMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/index.vue?macro=true";
import { default as indexyMPt7UysjqMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/index.vue?macro=true";
import { default as _91id_93SDFiFT0l3TMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/[id].vue?macro=true";
import { default as indexXu0LDJedqwMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/index.vue?macro=true";
import { default as _91id_93Bifq9MkDR8Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-classes/[id].vue?macro=true";
import { default as indexOxovAgQveMMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-classes/index.vue?macro=true";
import { default as _91id_939qcFy48ZK0Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-departments/[id].vue?macro=true";
import { default as indexyPbLm1rJKHMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-departments/index.vue?macro=true";
import { default as indexZF7QCP8OZwMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/index.vue?macro=true";
import { default as _91id_9313ly946qJfMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/periods/[id].vue?macro=true";
import { default as indexNubDhegAfEMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/periods/index.vue?macro=true";
import { default as account_45performanceW4FOgLzBn8Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/[id]/account-performance.vue?macro=true";
import { default as indextFnKuYHRZbMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/[id]/index.vue?macro=true";
import { default as performanceqxmFbBUaN3Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/[id]/performance.vue?macro=true";
import { default as indexM1b2gxilIAMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/index.vue?macro=true";
import { default as indexnBOWnSmPkOMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/performance/index.vue?macro=true";
import { default as _91id_9383zboItMFmMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/transaction-categories/[id].vue?macro=true";
import { default as indexgIERIRPEgWMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/transaction-categories/index.vue?macro=true";
import { default as _91id_93qWJEYrclpvMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/transactions/[id].vue?macro=true";
import { default as indextRNERkFWXpMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/investments/transactions/index.vue?macro=true";
import { default as indexYFRRT4UhrDMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/kr-dashboard/index.vue?macro=true";
import { default as indexByCfSm9rhXMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/login-metrics/index.vue?macro=true";
import { default as _91id_93anRlKsShEcMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/[id].vue?macro=true";
import { default as indexv5ehSEXVKwMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/index.vue?macro=true";
import { default as _91id_93our0PG7Hw9Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/[id].vue?macro=true";
import { default as indexZRT3IiGtAEMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/index.vue?macro=true";
import { default as indexXjYEgGyebSMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/member-interactions/index.vue?macro=true";
import { default as indexFuTBTW2SVTMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/my-daf-metrics/index.vue?macro=true";
import { default as indexRTd46E836xMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/my-giving-page-metrics/index.vue?macro=true";
import { default as _91id_93nlo3O4mIIfMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/news/[id].vue?macro=true";
import { default as index4vvG0Wx9ihMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/news/index.vue?macro=true";
import { default as index8L1bj8soh4Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/onboarding-metrics/index.vue?macro=true";
import { default as indexbNY1gfbuGBMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/our-recommendations-page-metrics/index.vue?macro=true";
import { default as indexoS0C7PSOPBMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledge-metrics/index.vue?macro=true";
import { default as countersignHkWdFDN7edMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/countersign.vue?macro=true";
import { default as indexq7bOG90WUjMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/index.vue?macro=true";
import { default as index5jrWwjyC2QMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/pledges/index.vue?macro=true";
import { default as index3STmafjEEvMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/gift-account-balances/index.vue?macro=true";
import { default as indexfoYVu5t8VJMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/index.vue?macro=true";
import { default as indexyUXkMa8TejMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/monthly-helper/index.vue?macro=true";
import { default as indexxa3654w6wkMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-gift-accounts/[id]/index.vue?macro=true";
import { default as indexJf2hjNkksXMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-gift-accounts/index.vue?macro=true";
import { default as indexn4zrsekfELMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-to-fund-associations/index.vue?macro=true";
import { default as indexKxynv56RL5Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-transactions/index.vue?macro=true";
import { default as indexG0ndR1WYFFMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/salesforce-cases/index.vue?macro=true";
import { default as indexpRDM55PGEDMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/settings-page-metrics/index.vue?macro=true";
import { default as indexMnWgCdvHg0Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/settings/index.vue?macro=true";
import { default as signinN69SQ2BUjZMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/signin.vue?macro=true";
import { default as _91id_93ll1q76GrioMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/[id].vue?macro=true";
import { default as index3KnJ1gHbfNMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/index.vue?macro=true";
import { default as add_45pledge0WicOVXZXnMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/add-pledge.vue?macro=true";
import { default as indexigKWCkrRu8Meta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/index.vue?macro=true";
import { default as indexSPVlqhWZluMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/index.vue?macro=true";
import { default as newC8fN3gICkgMeta } from "/opt/build/repo/packages/member-webapp-admin/pages/users/new.vue?macro=true";
export default [
  {
    name: "2fa",
    path: "/2fa",
    meta: indexY5Ny83IJl7Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/2fa/index.vue")
  },
  {
    name: "advisors-metrics",
    path: "/advisors-metrics",
    meta: indexEg47hzBIZEMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/advisors-metrics/index.vue")
  },
  {
    name: "amended-pledges-id-countersign",
    path: "/amended-pledges/:id()/countersign",
    meta: countersignxF468bk52nMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/countersign.vue")
  },
  {
    name: "amended-pledges-id",
    path: "/amended-pledges/:id()",
    meta: indexquLjNQi5bJMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/[id]/index.vue")
  },
  {
    name: "amended-pledges",
    path: "/amended-pledges",
    meta: indexvUiFYwtHE3Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/amended-pledges/index.vue")
  },
  {
    name: "assistants-id",
    path: "/assistants/:id()",
    meta: _91id_93Yss1e8Yu4WMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/assistants/[id].vue")
  },
  {
    name: "assistants",
    path: "/assistants",
    meta: indexdNY8DGfBpwMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/assistants/index.vue")
  },
  {
    name: "audit-logs-id",
    path: "/audit-logs/:id()",
    meta: _91id_935kG9ieVG72Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/[id].vue")
  },
  {
    name: "audit-logs",
    path: "/audit-logs",
    meta: index2Cj19LEknmMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/audit-logs/index.vue")
  },
  {
    name: "bank-reconciliation-id",
    path: "/bank-reconciliation/:id()",
    meta: _91id_93ckIl4qqogYMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/[id].vue")
  },
  {
    name: "bank-reconciliation",
    path: "/bank-reconciliation",
    meta: indexOBwgeXeQWgMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/bank-reconciliation/index.vue")
  },
  {
    name: "case-reporting",
    path: "/case-reporting",
    meta: indexKxo8gIqhSRMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/case-reporting/index.vue")
  },
  {
    name: "charity-categories-id",
    path: "/charity-categories/:id()",
    meta: _91id_93g28Z6RfmhSMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/[id].vue")
  },
  {
    name: "charity-categories",
    path: "/charity-categories",
    meta: index3hcyHNApYyMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/charity-categories/index.vue")
  },
  {
    name: "chats-id",
    path: "/chats/:id()",
    meta: _91id_931sy2GPaLRvMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/chats/[id].vue")
  },
  {
    name: "chats",
    path: "/chats",
    meta: index7X2qLIYWC2Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/chats/index.vue")
  },
  {
    name: "daf-statements-id",
    path: "/daf-statements/:id()",
    meta: _91id_9346pk727ws7Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/[id].vue")
  },
  {
    name: "daf-statements",
    path: "/daf-statements",
    meta: indexSS7bWQuPRGMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/daf-statements/index.vue")
  },
  {
    name: "endorsements-metrics",
    path: "/endorsements-metrics",
    meta: indexJBOxt0OoVdMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/endorsements-metrics/index.vue")
  },
  {
    name: "forecasted-impact-page-metrics",
    path: "/forecasted-impact-page-metrics",
    meta: indexq13h1QKYueMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/forecasted-impact-page-metrics/index.vue")
  },
  {
    name: "giving-recommendations-endorsements",
    path: "/giving-recommendations-endorsements",
    meta: indexbA3doqd1UfMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations-endorsements/index.vue")
  },
  {
    name: "giving-recommendations-id",
    path: "/giving-recommendations/:id()",
    meta: _91id_93tog9sTshJQMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/[id].vue")
  },
  {
    name: "giving-recommendations",
    path: "/giving-recommendations",
    meta: indexezfcBaujDcMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/giving-recommendations/index.vue")
  },
  {
    name: "grants",
    path: "/grants",
    meta: index5OIQwW7y2HMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/grants/index.vue")
  },
  {
    name: "home-metrics",
    path: "/home-metrics",
    meta: indextuJcs3h1A5Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/home-metrics/index.vue")
  },
  {
    name: "how-we-measure-impact-id",
    path: "/how-we-measure-impact/:id()",
    meta: _91id_93j3dpEgnvs7Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/[id].vue")
  },
  {
    name: "how-we-measure-impact",
    path: "/how-we-measure-impact",
    meta: indexLyCIsAL10JMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/how-we-measure-impact/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexyMPt7UysjqMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/index.vue")
  },
  {
    name: "investments-accounts-id",
    path: "/investments/accounts/:id()",
    meta: _91id_93SDFiFT0l3TMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/[id].vue")
  },
  {
    name: "investments-accounts",
    path: "/investments/accounts",
    meta: indexXu0LDJedqwMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/index.vue")
  },
  {
    name: "investments-accounts-sage-classes-id",
    path: "/investments/accounts/sage-classes/:id()",
    meta: _91id_93Bifq9MkDR8Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-classes/[id].vue")
  },
  {
    name: "investments-accounts-sage-classes",
    path: "/investments/accounts/sage-classes",
    meta: indexOxovAgQveMMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-classes/index.vue")
  },
  {
    name: "investments-accounts-sage-departments-id",
    path: "/investments/accounts/sage-departments/:id()",
    meta: _91id_939qcFy48ZK0Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-departments/[id].vue")
  },
  {
    name: "investments-accounts-sage-departments",
    path: "/investments/accounts/sage-departments",
    meta: indexyPbLm1rJKHMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/accounts/sage-departments/index.vue")
  },
  {
    name: "investments",
    path: "/investments",
    meta: indexZF7QCP8OZwMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/index.vue")
  },
  {
    name: "investments-periods-id",
    path: "/investments/periods/:id()",
    meta: _91id_9313ly946qJfMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/periods/[id].vue")
  },
  {
    name: "investments-periods",
    path: "/investments/periods",
    meta: indexNubDhegAfEMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/periods/index.vue")
  },
  {
    name: "investments-pools-id-account-performance",
    path: "/investments/pools/:id()/account-performance",
    meta: account_45performanceW4FOgLzBn8Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/[id]/account-performance.vue")
  },
  {
    name: "investments-pools-id",
    path: "/investments/pools/:id()",
    meta: indextFnKuYHRZbMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/[id]/index.vue")
  },
  {
    name: "investments-pools-id-performance",
    path: "/investments/pools/:id()/performance",
    meta: performanceqxmFbBUaN3Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/[id]/performance.vue")
  },
  {
    name: "investments-pools",
    path: "/investments/pools",
    meta: indexM1b2gxilIAMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/index.vue")
  },
  {
    name: "investments-pools-performance",
    path: "/investments/pools/performance",
    meta: indexnBOWnSmPkOMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/pools/performance/index.vue")
  },
  {
    name: "investments-transaction-categories-id",
    path: "/investments/transaction-categories/:id()",
    meta: _91id_9383zboItMFmMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/transaction-categories/[id].vue")
  },
  {
    name: "investments-transaction-categories",
    path: "/investments/transaction-categories",
    meta: indexgIERIRPEgWMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/transaction-categories/index.vue")
  },
  {
    name: "investments-transactions-id",
    path: "/investments/transactions/:id()",
    meta: _91id_93qWJEYrclpvMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/transactions/[id].vue")
  },
  {
    name: "investments-transactions",
    path: "/investments/transactions",
    meta: indextRNERkFWXpMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/investments/transactions/index.vue")
  },
  {
    name: "kr-dashboard",
    path: "/kr-dashboard",
    meta: indexYFRRT4UhrDMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/kr-dashboard/index.vue")
  },
  {
    name: "login-metrics",
    path: "/login-metrics",
    meta: indexByCfSm9rhXMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/login-metrics/index.vue")
  },
  {
    name: "match-campaign-categories-id",
    path: "/match-campaign-categories/:id()",
    meta: _91id_93anRlKsShEcMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/[id].vue")
  },
  {
    name: "match-campaign-categories",
    path: "/match-campaign-categories",
    meta: indexv5ehSEXVKwMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaign-categories/index.vue")
  },
  {
    name: "match-campaigns-id",
    path: "/match-campaigns/:id()",
    meta: _91id_93our0PG7Hw9Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/[id].vue")
  },
  {
    name: "match-campaigns",
    path: "/match-campaigns",
    meta: indexZRT3IiGtAEMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/match-campaigns/index.vue")
  },
  {
    name: "member-interactions",
    path: "/member-interactions",
    meta: indexXjYEgGyebSMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/member-interactions/index.vue")
  },
  {
    name: "my-daf-metrics",
    path: "/my-daf-metrics",
    meta: indexFuTBTW2SVTMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/my-daf-metrics/index.vue")
  },
  {
    name: "my-giving-page-metrics",
    path: "/my-giving-page-metrics",
    meta: indexRTd46E836xMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/my-giving-page-metrics/index.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: _91id_93nlo3O4mIIfMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: index4vvG0Wx9ihMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/news/index.vue")
  },
  {
    name: "onboarding-metrics",
    path: "/onboarding-metrics",
    meta: index8L1bj8soh4Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/onboarding-metrics/index.vue")
  },
  {
    name: "our-recommendations-page-metrics",
    path: "/our-recommendations-page-metrics",
    meta: indexbNY1gfbuGBMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/our-recommendations-page-metrics/index.vue")
  },
  {
    name: "pledge-metrics",
    path: "/pledge-metrics",
    meta: indexoS0C7PSOPBMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledge-metrics/index.vue")
  },
  {
    name: "pledges-id-countersign",
    path: "/pledges/:id()/countersign",
    meta: countersignHkWdFDN7edMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/countersign.vue")
  },
  {
    name: "pledges-id",
    path: "/pledges/:id()",
    meta: indexq7bOG90WUjMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledges/[id]/index.vue")
  },
  {
    name: "pledges",
    path: "/pledges",
    meta: index5jrWwjyC2QMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/pledges/index.vue")
  },
  {
    name: "sage-salesforce-gift-account-balances",
    path: "/sage-salesforce/gift-account-balances",
    meta: index3STmafjEEvMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/gift-account-balances/index.vue")
  },
  {
    name: "sage-salesforce",
    path: "/sage-salesforce",
    meta: indexfoYVu5t8VJMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/index.vue")
  },
  {
    name: "sage-salesforce-monthly-helper",
    path: "/sage-salesforce/monthly-helper",
    meta: indexyUXkMa8TejMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/monthly-helper/index.vue")
  },
  {
    name: "sage-salesforce-sage-gift-accounts-id",
    path: "/sage-salesforce/sage-gift-accounts/:id()",
    meta: indexxa3654w6wkMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-gift-accounts/[id]/index.vue")
  },
  {
    name: "sage-salesforce-sage-gift-accounts",
    path: "/sage-salesforce/sage-gift-accounts",
    meta: indexJf2hjNkksXMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-gift-accounts/index.vue")
  },
  {
    name: "sage-salesforce-sage-to-fund-associations",
    path: "/sage-salesforce/sage-to-fund-associations",
    meta: indexn4zrsekfELMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-to-fund-associations/index.vue")
  },
  {
    name: "sage-salesforce-sage-transactions",
    path: "/sage-salesforce/sage-transactions",
    meta: indexKxynv56RL5Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/sage-transactions/index.vue")
  },
  {
    name: "sage-salesforce-salesforce-cases",
    path: "/sage-salesforce/salesforce-cases",
    meta: indexG0ndR1WYFFMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/sage-salesforce/salesforce-cases/index.vue")
  },
  {
    name: "settings-page-metrics",
    path: "/settings-page-metrics",
    meta: indexpRDM55PGEDMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/settings-page-metrics/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexMnWgCdvHg0Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/settings/index.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinN69SQ2BUjZMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/signin.vue")
  },
  {
    name: "target-audiences-id",
    path: "/target-audiences/:id()",
    meta: _91id_93ll1q76GrioMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/[id].vue")
  },
  {
    name: "target-audiences",
    path: "/target-audiences",
    meta: index3KnJ1gHbfNMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/target-audiences/index.vue")
  },
  {
    name: "users-id-add-pledge",
    path: "/users/:id()/add-pledge",
    meta: add_45pledge0WicOVXZXnMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/add-pledge.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexigKWCkrRu8Meta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/[id]/index.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexSPVlqhWZluMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/index.vue")
  },
  {
    name: "users-new",
    path: "/users/new",
    meta: newC8fN3gICkgMeta || {},
    component: () => import("/opt/build/repo/packages/member-webapp-admin/pages/users/new.vue")
  }
]